<template>
    <div class="container">


<div class="row ">

    <div class=" col-md-12">
       <div class="display-2" > New   Buyer Applications </div>
    </div>


</div>

<div class="row ">

    <div class=" col-md-9">

        <div class=" align-items-center">
              <v-data-table
      :headers="headers"
      :items="signups"
      sort-by="name"
      class="elevation-1"
    >
<template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.created_at | date_format  }}
                  </td>
                  <td>
                    {{ item.firstName }} {{ item.lastName }}
                  </td>
                  <td> {{item.companyName}}  </td>
                 
                 <td> {{item.status}}  </td>
                  <td>
                    <template>
                      <v-icon
                        small
                        class="mr-2"
                        @click="
                          $router.push(
                            '/admin/buyer-detail/' + item.id
                          )
                        "
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                  </td>
                </tr>
              </template>



              </v-data-table>
        </div>
    </div>

</div>
    </div>
</template>


<script>
import SET_BREADCRUMB  from "@/core/services/store/breadcrumbs.module";
import gql from "graphql-tag";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";


  export default {

apollo: {
  signups: gql`
    query Signups {
      signups {
        id
        status
    companyName
      firstName
        created_at
      lastName
      email
      website
      about
      }
    }
  `
},

 components: {

  },















    data () {
      return {

        
         

        componentKey: 0,
      api_url: process.env.VUE_APP_STRAPI_API_URL,

      
   
       headers: [
      { text: "ID"},
    { text: "Date" },
    { text: "Name" },
    { text: "Company" },
{ text: "Status" },
{ text: "Actions" },
    
    ],

        
        tabs: 0,
       
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
 mounted() {
    setTimeout(() => {
      this.componentKey += 1;this.$store.dispatch(SET_BREADCRUMB, [{ path: "/dashboard" }]);
    }, 500);
  },

 


  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    fullName(user) {
      return (user.FirstName || "") + " " + (user.LastName || "");
    }
  },




    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
  }
</script>


